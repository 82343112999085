<template>
    <div>
        <RadialSpinner v-if=done></RadialSpinner>
        <div v-else>
            <h2 class="componentTitle">{{ changeYourName }}</h2>
            <p class="card-text mb-0">{{ migratedUserMessage }}</p>
            <a class="card-link-text" href="https://www.rsc.org/help-legal/help/#account" target="_blank">{{ learnMore
                }}</a>
            <p class="card-text">{{ correctKeyDetails }}</p>
            <v-form validate-on="submit" @submit.prevent v-model="isValid">
                <div :class="{ 'mb-[18px!important]': !newUserTitleError }">
                    <p class="label">{{ enterTitleLabel }}</p>
                    <FormSelect id="select-title" v-model="newUserTitle" :options="titleSelectOptions"
                        :error-message="newUserTitleError" @update:modelValue="newUserTitleError = ''"
                        @validation-error="newUserTitleError = $event" />
                </div>
                <div>
                    <p class="label">{{ enterNewFirstNameLabel }}</p>
                    <v-text-field class="input" v-bind="vProps" v-model="newUserFirstName" :rules="rules.firstName"
                        validate-on="blur"></v-text-field>
                </div>
                <div>
                    <p class="label">{{ enterNewLastNameLabel }}</p>
                    <v-text-field class="input" v-bind="vProps" v-model="newUserLastName" :rules="rules.lastName"
                        validate-on="blur"></v-text-field>
                </div>
                <ErrorsManager :errorType="errorType"></ErrorsManager>
                <div class="buttons-wrapper">
                    <RadialSpinner v-if="loading"></RadialSpinner>
                    <button v-else type="submit" @click="submitForm" class="blueBgButton">
                        {{ buttonSave }}
                    </button>
                </div>
            </v-form>
        </div>
    </div>
</template>
<script lang="ts">
import { Resources } from '@/core/models/metadata/resources';
import { Services } from '@/core/models/metadata/services';
import { inject } from 'vue';
import { defineComponent } from 'vue';
import { Title } from '@/core/models/metadata/title'
import { ErrorType } from '@/core/models/metadata/error';
import { ValidationRulesService } from '@/core/services/validationRulesService';
import { UpdateUserRequest } from '@/core/models/requests/updateUserRequest';
import { UpdateUserService } from '@/core/services/updateUserService';
import RadialSpinner from "@/components/shared/RadialSpinner.vue";
import ErrorsManager from "@/components/shared/ErrorsManager.vue";
import { JwtWrapper } from '@/core/services/jwtWrapper';
import FormSelect from '@/components/shared/FormSelect.vue';
import Cookies from 'js-cookie';

let updateUserService!: UpdateUserService;
export default defineComponent({
    created(){
        if (Cookies.get('_ga'))
   {
       const script1 = document.createElement("script");
       script1.type = "text/javascript";
       script1.textContent = `function OptanonWrapper() { }`;
 
       const script2 = document.createElement("script");
       script2.type = "text/javascript";            
 
       script2.textContent = `window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
             clientName: '${JwtWrapper.jwtClaims.clientId}',
             clientID: '${JwtWrapper.jwtClaims.clientName }'
         });
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
         'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.VUE_APP_GTM_AUTH}&gtm_preview=${process.env.VUE_APP_GTM_PREVIEW}&gtm_cookies_win=${process.env.VUE_APP_GTM_COOKIES_WIN}';f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','${process.env.VUE_APP_GTM_ID}');`;
 
       document.head.insertBefore(script1, document.head.firstChild);
       script1.insertAdjacentElement("afterend",script2);
   }
    const { state, session_token } = this.$route.query as { state: string, session_token: string };
    JwtWrapper.tryPersist(state, session_token, this.$router);
},
        
    data() {
     
        updateUserService = inject(Services.UpdateUser) as UpdateUserService;
        const validationRules = inject(Services.ValidationRules) as ValidationRulesService;
        const resources = inject(Services.Resources) as Resources;
        return {
            newUserTitleError: '',
            newClientName: JwtWrapper.jwtClaims.clientName  ?? '',
            newClientId: JwtWrapper.jwtClaims.clientId  ?? '',
            newUserTitle: JwtWrapper.jwtClaims.title ?? '',
            newUserFirstName: JwtWrapper.jwtClaims.firstName ?? '',
            newUserLastName:  JwtWrapper.jwtClaims.lastName ??'',
            buttonSave: resources.buttons.continue,
            changeYourName: resources.titles.changeYourName,
            loading: false,
            rules: validationRules.getValidationRules(),
            isValid: false,
            enterNewFirstNameLabel: resources.messages.firstName,
            enterNewLastNameLabel: resources.messages.lastName,
            enterTitleLabel: resources.messages.title,
            titles: Object.values(Title),
            errorType: ErrorType.UpdateFields,
            done: false,
            migratedUserMessage: resources.messages.migratedUserMessage,
            learnMore: resources.messages.learnMore,
            correctKeyDetails: resources.messages.correctKeyDetails,
            dropdownOpen: false,
            activeDescendant: '',
            titleSelectOptions: Object.values(Title).map(title => ({
                text: title,
                value: title,
            }))
        };
    },
    
    computed: {
        isDisabled() {
            return {
                "disabled-button": !this.isValid,
            };
        },
        vProps(): {
            variant: "outlined" | "filled" | "plain" | "underlined" | "solo" | "solo-inverted" | "solo-filled" | undefined,
            density: null | 'default' | 'comfortable' | 'compact' | undefined
        } {
            return {
                variant: "outlined",
                density: "compact",
            };
        }
    },
    methods: {
        async submitForm() {
            if (!this.isValid) return;
            this.loading = true;
            const updateUserNamesQuery: UpdateUserRequest = {
                title: this.newUserTitle,
                firstName: this.newUserFirstName,
                lastName: this.newUserLastName,
            };
            const response = await updateUserService.handle(
                updateUserNamesQuery
            );
            if (response.isSuccess) {
                this.done = true;
                localStorage.removeItem('jwt');
                window.location.href = JwtWrapper.getContinueEndpoint();
            }

            this.loading = false;
        },
        onFocus() {
            this.dropdownOpen = true;
        },
        onKeydown(event: KeyboardEvent) {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
            }
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        }
    },
    components: {
        ErrorsManager,
        RadialSpinner,
        FormSelect
    }
})

</script>
<style lang="sass" scoped>

.buttons-wrapper
  display: flex
  justify-content: flex-end

.mb-0
    margin-bottom: 0px

.card-link-text
    margin-bottom: 20px
    font-size: 1rem
    font-weight: normal

.componentTitle
    margin-bottom: 10px

.card-link-text:hover
    text-decoration: underline

@media all and (max-width: 800px)
  .buttons-wrapper
    justify-content: flex-start

  .blueBgButton
    font-size: 1rem
  </style>