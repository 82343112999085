<template>
    <div>
        <h2 class="componentTitle">{{ emailVerifiedTitle }}</h2>
        <p class="card-text">{{ emailVerifiedMessage }}</p>
    
        <div class="buttons-wrapper">            
            <button class="blueBgButton" @click="next">
                {{ emailVerifiedText }}
            </button>
        </div>
    </div>
</template>
<script lang="ts">
import { Resources } from '@/core/models/metadata/resources';
import { Services } from '@/core/models/metadata/services';
import { inject, defineComponent } from 'vue';
import { JwtWrapper } from "@/core/services/jwtWrapper";
import Cookies from 'js-cookie';


export default defineComponent({
    created() {
        scriptBuilder();
  },
    data(){
        const resources = inject(Services.Resources) as Resources;
        return {
            emailVerifiedTitle: resources.titles.emailVerified,
            emailVerifiedMessage: resources.messages.emailVerified,
            emailVerifiedText: resources.buttons.emailVerified,
            title: JwtWrapper.jwtClaims?.title,
            firstName: JwtWrapper.jwtClaims?.firstName,
            lastName: JwtWrapper.jwtClaims?.lastName
        };
    },
    methods: {
        next(){
            if (!this.title && !this.firstName && !this.lastName) {
            this.$router.push('/userData');
            }
            else {
                window.location.href = JwtWrapper.getContinueEndpoint();
            }
        }
    }
})

const scriptBuilder = () => {
  
  //if cookie has been dropped before on this domain, assume user has consented to GA via onetrust at a different domain
  if (Cookies.get('_ga'))
  {
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.textContent = `function OptanonWrapper() { }`;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.textContent = `window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
             clientName: '${JwtWrapper.jwtClaims.clientId}',
             clientID: '${JwtWrapper.jwtClaims.clientName }'
        });
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.VUE_APP_GTM_AUTH}&gtm_preview=${process.env.VUE_APP_GTM_PREVIEW}&gtm_cookies_win=${process.env.VUE_APP_GTM_COOKIES_WIN}';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.VUE_APP_GTM_ID}');`;

      document.head.insertBefore(script1, document.head.firstChild);
      script1.insertAdjacentElement("afterend",script2);
  }
}
</script>